<template>
    <v-container>
        <notifications />

        <v-row>
            <v-col cols="8">
                <v-card>
                    <v-card-text>
                        <ol>
                            <li v-for="(item, key) in table.items" :key="item.id">
                                <div>{{ item.pertanyaan }}</div>
                                <v-radio-group v-model="table.items[key].jawaban">
                                    <v-radio :label="'A. ' + item.jawaban1" :value="item.jawaban1"></v-radio>
                                    <v-radio :label="'B. ' + item.jawaban2" :value="item.jawaban2"></v-radio>
                                    <v-radio :label="'C. ' + item.jawaban3" :value="item.jawaban3"></v-radio>
                                    <v-radio :label="'D. ' + item.jawaban4" :value="item.jawaban4"></v-radio>
                                    <v-radio :label="'E. ' + item.jawaban5" :value="item.jawaban5"></v-radio>
                                    <v-btn color="primary" x-small @click="simpanJawaban(key, item)">Simpan
                                        Jawaban</v-btn>
                                </v-radio-group>
                            </li>
                        </ol>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mb-4">
                    <v-card-text>
                        <h2 style="text-transform: capitalize;">POST TEST</h2>
                        <!-- :deadline="ujian.waktu_batas"  -->
                        <h3 style="text-transform: capitalize;">{{ pelatihan.nama }}</h3>
                        Test akan berakhir dalam:
                        <flip-countdown :deadline="ujian.waktu_batas" :showDays="false" :showHours="false"
                            @timeElapsed="selesaiTest(true)"></flip-countdown>
                        <v-btn color="error" block @click="selesaiTest()">Selesai Test</v-btn>

                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-text>
                        <span v-for="(item, key) in tableNomer.items" :key="item.id" class="mr-1 mb-1">
                            <v-avatar v-if="item.jawaban == null" color="primary" size="30"><span
                                    class="white--text text-h7">{{ key +
                                        1 }}</span></v-avatar>
                            <v-avatar v-else color="success" size="30"><span class="white--text text-h7">{{ key +
                                1 }}</span></v-avatar>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<style scoped>
.theme--light.v-card>.v-card__text,
.theme--light.v-card>.v-card__subtitle {
    color: black;
}

.v-label {
    color: black !important;
}
</style>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil } from '@mdi/js'
import FlipCountdown from 'vue2-flip-countdown'

export default {
    components: {
        FlipCountdown
    },
    data() {
        return {
            id_pelatihan: this.$route.params.id_pelatihan,
            data: {
                nama_institusi: '',
                nama_prodi: '',
                profesi: '',
                stase: '',
                tanggal_awal: '',
                tanggal_akhir: '',
            },
            icons: {
                mdiDelete,
                mdiPencil,
            },
            pelatihan: {},
            ujian: { waktu_batas: "2024-08-24 00:00:01" },
            table: {
                loading: true,
                items: [],
                totalItems: 0,
            },
            tableNomer: {
                items: [],
            },
        }
    },
    watch: {
    },
    computed: {
    },
    created() {
        this.getPertanyaans()
        this.getNomerUjian()
        this.getPelatihan()
        this.getUjian()
    },
    methods: {
        getPelatihan() {
            const fmData = new FormData()
            fmData.append('id_pelatihan', this.id_pelatihan)
            axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
                .then(response => {
                    this.pelatihan = Object.assign({}, response.data)
                })
                .catch(error => {
                    alert(error)
                })
        },
        getUjian() {
            const fmData = new FormData()
            fmData.append('id_pelatihan', this.id_pelatihan)
            fmData.append('jenis', 'POST TEST')
            axiosPostAuth('api/CBT/getUjian', fmData)
                .then(response => {
                    this.ujian = Object.assign({}, response.data)

                    var nowDate= new Date();
                    var batas = new Date(this.ujian.waktu_batas);
                    var sekarang = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), nowDate.getHours(), nowDate.getMinutes(), nowDate.getSeconds());
                    if (sekarang > batas) {
                        this.todoSelesai()
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        getPertanyaans() {
            const fmData = new FormData()
            fmData.append('id_pelatihan', this.id_pelatihan)
            fmData.append('jenis', 'POST TEST')
            axiosPostAuth('api/CBT/getPertanyaanUjian', fmData)
                .then(response => {
                    this.table.items = response.data
                })
                .catch(error => {
                    alert(error)
                })
        },
        getNomerUjian() {
            const fmData = new FormData()
            fmData.append('id_pelatihan', this.id_pelatihan)
            fmData.append('jenis', 'POST TEST')
            axiosPostAuth('api/CBT/getNomerUjian', fmData)
                .then(response => {
                    this.tableNomer.items = response.data
                })
                .catch(error => {
                    alert(error)
                })
        },
        simpanJawaban(key, item) {
            const fmData = new FormData()
            fmData.append('id_soal', item.id)
            fmData.append('jawaban', this.table.items[key].jawaban)
            axiosPostAuth('api/CBT/saveJawaban', fmData)
                .then(response => {
                    if (response.code === 200) {
                        this.$notify({
                            type: 'success',
                            title: 'Berhasil!',
                            text: response.message,
                        });
                        this.getNomerUjian()
                    }
                    else {
                        alert(response.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        selesaiTest(direct = false) {
            if (direct == true) {
                this.todoSelesai()
            } else {
                if (confirm("Apakah yakin akan menyelesaikan test?")) {
                    this.todoSelesai()
                }
            }
        },
        todoSelesai() {
            const fmData = new FormData()
            fmData.append('id_pelatihan', this.id_pelatihan)
            fmData.append('jenis', 'POST TEST')
            fmData.append('id_ujian', this.ujian.id)
            axiosPostAuth('api/CBT/selesaiTest', fmData)
                .then(response => {
                    if (response.code === 200) {
                        alert("Test telah berakhir!")
                        this.$router.push({ name: 'PesertaPelatihanPostTest', params: { id_pelatihan: this.id_pelatihan } })
                    }
                    else {
                        alert(response.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        }
    },
}
</script>